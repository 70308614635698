import { useEffect, useRef, useState } from "react";

import assetUtil from "../../../../../utils/asset.util";

import { useLanguage } from "../../../../language.context";

import { useAppState } from "../../appState.context";

const getMsUntilNextGetMediaChanges = (medias) => {
  if (medias.length < 10) {
    return 1000 * 1 * 60;
  } else if (medias.length < 20) {
    return 1000 * 2 * 60;
  } else {
    return 1000 * 5 * 60;
  }
};

export default () => {
  const appStateCtx = useAppState();
  const languageCtx = useLanguage();

  const [media, setMedia] = useState();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const showNextImageIdRef = useRef(0);
  const getMediaChangesIdRef = useRef(0);

  useEffect(() => {
    const medias = appStateCtx.medias.get();

    const showNextImage = () => {
      if (medias.length === 0) {
        setMedia();
      } else {
        const index = Math.floor(Math.random() * medias.length);

        setMedia(medias[index]);
      }

      showNextImageIdRef.current = setTimeout(showNextImage, 20 * 1000);
    };

    showNextImage();

    return () => {
      clearTimeout(showNextImageIdRef.current);
    };
  }, [appStateCtx.medias.get()]);

  useEffect(() => {
    const medias = appStateCtx.medias.get();

    const getMediaChanges = () => {
      try {
        appStateCtx.medias.refreshFromChanges();
      } catch (ex) {
        //console.log(ex);
      }
      getMediaChangesIdRef.current = setTimeout(
        getMediaChanges,
        getMsUntilNextGetMediaChanges(medias)
      );
    };

    // update every 5 min
    getMediaChangesIdRef.current = setTimeout(
      getMediaChanges,
      getMsUntilNextGetMediaChanges(medias)
    );

    return () => {
      clearTimeout(getMediaChangesIdRef.current);
    };
  }, [appStateCtx.medias.get()]);

  useEffect(() => {
    const onFullscreenChanged = ({ matches }) => {
      setIsFullscreen(!!matches);
    };
    const mediaMatch = window.matchMedia("(display-mode: fullscreen)");
    mediaMatch.addEventListener("change", onFullscreenChanged);
    return () => {
      mediaMatch.removeEventListener("change", onFullscreenChanged);
    };
  }, []);

  const handleStartClicked = () => {
    setIsFullscreen(true);
    document.getElementById("fullscreen").requestFullscreen();
  };

  return (
    <div
      className="position-absolute top-0 bottom-0 left-0 right-0 d-flex flex-column justify-content-center align-items-center"
      style={{ backgroundColor: "black" }}
    >
      {!isFullscreen && (
        <>
          <div>
            <img src="/assets/fulllogo5.png" alt="" style={{ height: 100 }} />
          </div>
          <div className="fs-1 mt-4 text-center" style={{ color: "white" }}>
            {languageCtx.getStringFromId("Welcome to Fotisima slideshow")}
          </div>
          <div className="fs-3 mt-4 text-center" style={{ color: "white" }}>
            {languageCtx.getStringFromId(
              "Photos from your album will be randomly chosen and change every 20 seconds"
            )}
            <br />
            {languageCtx.getStringFromId(
              "Connect your device to a projector or a big screen and enjoy the show"
            )}
          </div>
          <div className="mt-5">
            <button
              className="btn btn-blue btn-lg"
              onClick={handleStartClicked}
            >
              {languageCtx.getStringFromId("Start")}
            </button>
          </div>
        </>
      )}
      <div
        id="fullscreen"
        className="d-flex flex-column justify-content-center align-items-center"
      >
        {isFullscreen && !media && (
          <>
            <div>
              <img src="/assets/fulllogo5.png" alt="" style={{ height: 100 }} />
            </div>
            <div className="fs-1 mt-4 text-center" style={{ color: "white" }}>
              {languageCtx.getStringFromId(
                "Upload photos to the shared album using Fotisima"
              )}
              <br />
              {languageCtx.getStringFromId("and see them here!")}
            </div>
          </>
        )}
        {isFullscreen && media && (
          <>
            {assetUtil.isPhoto(media.asset) && (
              <img
                src={media?.reducedFile?.uri}
                alt=""
                className="w-100 h-100 object-fit-contain"
              />
            )}
            {assetUtil.isVideo(media.asset) && (
              <video
                autoplay
                muted
                loop
                playsinline
                src={media?.reducedFile?.uri}
                alt=""
                className="w-100 h-100 object-fit-contain"
              />
            )}
            <div className="position-absolute bottom-0 left-0 p-3">
              <img
                src="/assets/fulllogo5.png"
                style={{ height: 50, display: "inline" }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
