import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import {
  faImage,
  faUsers,
  faEnvelope,
  faFont,
  faBox,
  faCalendarDays,
  faInfo,
  faPhotoVideo,
} from "@fortawesome/free-solid-svg-icons";

import userUtil from "../../../../../../../utils/user.util";
import albumUtil from "../../../../../../../utils/album.util";

import { useLanguage } from "../../../../../../language.context";
import ButtonMenuControl from "../../../../../../controls/buttonmenu.control";

import { useAppState } from "../../../../appState.context";

import InvitecardsControl from "./invitecards.control";
import MembersControl from "./members/members.control";
import EditNameControl from "./editName.control";
import EditCoverControl from "./editCover.control";
import EditStartdateControl from "./editStartdate.control";
import EditPackageControl from "./editPackage/edit.control";
import InfoControl from "./info.control";

export default ({ ctx }) => {
  const navigate = useNavigate();

  const appStateCtx = useAppState();
  const languageCtx = useLanguage();

  const items = [
    {
      titleLanguageStringId: languageCtx.getIdFromId("Info"),
      icon: faInfo,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Info"), (ctx) => (
          <InfoControl ctx={ctx} />
        ));
      },
      visible: () => true,
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("members"),
      icon: faUsers,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("members"), (ctx) => (
          <MembersControl ctx={ctx} />
        ));
      },
      visible: () =>
        albumUtil.isUserOwner(
          appStateCtx.album.get(),
          appStateCtx.user.get()
        ) && !albumUtil.hasEnded(appStateCtx.album.get()),
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("Slideshow"),
      icon: faPhotoVideo,
      onClick: () => {
        navigate("./slideshow");
        // ctx.pushItem(languageCtx.getIdFromId("Slideshow"), (ctx) => (
        //   <InvitecardsControl ctx={ctx} />
        // ));
      },
      visible: () =>
        albumUtil.isUserOwner(
          appStateCtx.album.get(),
          appStateCtx.user.get()
        ) && !albumUtil.hasEnded(appStateCtx.album.get()),
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("Invite cards"),
      icon: faEnvelope,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("Invite cards"), (ctx) => (
          <InvitecardsControl ctx={ctx} />
        ));
      },
      visible: () =>
        albumUtil.isUserOwner(
          appStateCtx.album.get(),
          appStateCtx.user.get()
        ) && !albumUtil.hasEnded(appStateCtx.album.get()),
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("editName"),
      icon: faFont,
      onClick: () => {
        ctx.pushItem(languageCtx.getIdFromId("editName"), (ctx) => (
          <EditNameControl ctx={ctx} />
        ));
      },
      visible: () =>
        albumUtil.isUserOwner(
          appStateCtx.album.get(),
          appStateCtx.user.get()
        ) && !albumUtil.hasEnded(appStateCtx.album.get()),
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("changeCover"),
      icon: faImage,
      onClick: () => {
        ctx.updateCover.setCover(appStateCtx.album.get().cover);
        ctx.pushItem(
          languageCtx.getIdFromId("changeCover"),
          (ctx) => <EditCoverControl ctx={ctx} />,
          () => {
            ctx.updateCover.reset();
          }
        );
      },
      visible: () =>
        albumUtil.isUserOwner(
          appStateCtx.album.get(),
          appStateCtx.user.get()
        ) && !albumUtil.hasEnded(appStateCtx.album.get()),
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("changeStartDate"),
      icon: faCalendarDays,
      onClick: () => {
        ctx.updateStartDate.setStartDate(
          DateTime.fromISO(appStateCtx.album.get().startDate)
            .setZone(appStateCtx.album.get().timeZone)
            .toISODate()
        );
        ctx.pushItem(
          languageCtx.getIdFromId("changeStartDate"),
          (ctx) => <EditStartdateControl ctx={ctx} />,
          () => {
            ctx.updateStartDate.reset();
          }
        );
      },
      visible: () =>
        albumUtil.isUserOwner(
          appStateCtx.album.get(),
          appStateCtx.user.get()
        ) && !albumUtil.hasStarted(appStateCtx.album.get()),
    },
    {
      titleLanguageStringId: languageCtx.getIdFromId("changePackage"),
      icon: faBox,
      onClick: () => {
        ctx.pushItem(
          languageCtx.getIdFromId("changePackage"),
          (ctx) => <EditPackageControl ctx={ctx} />,
          () => {
            ctx.changePackage.reset();
          }
        );
      },
      visible: () => {
        return (
          !albumUtil.hasBiggestPackage(appStateCtx.album.get()) &&
          userUtil.isRegistered(appStateCtx.user.get()) &&
          !albumUtil.hasEnded(appStateCtx.album.get())
        );
      },
    },
  ];

  return <ButtonMenuControl items={items} />;
};
